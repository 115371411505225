import {getActiveHead,} from "@/assets/js/src/util/composables/useActiveHead"
import {useMessagesLoaded, useTranslation,} from "@/assets/js/src/util/composables"
import {computed,} from "vue"
import {genHeadOverrides,} from "@/assets/js/src/layout/meta"

export function useHeadInfo () {
    const {messagesLoaded,} = useMessagesLoaded([ 'nicodemus-ai', ])
    const {t,} = useTranslation()
    const head = getActiveHead()

    const title = computed(() => {
        return messagesLoaded.value
            ? t('async.nicodemus-ai.landing.title')
            : ''
    })

    const description = computed(() => {
        return messagesLoaded.value
            ? t('async.nicodemus-ai.intro')
            : ''
    })

    const ogImg = import.meta.glob('@/assets/images/nicodemus-ai/og-nicodemus-ai.jpg', {eager: true,})['/assets/images/nicodemus-ai/og-nicodemus-ai.jpg'].default

    let info = genHeadOverrides({title, description,})

    info.meta.push(...[
        {
            property: 'og:image',
            content: ogImg,
            vmid: 'og:image',
        },
        {
            property: 'og:image:width',
            content: "1200",
            vmid: 'og:image:width',
        },
        {
            property: 'og:image:height',
            content: "630",
            vmid: 'og:image:height',
        },
        {
            name: 'twitter:image',
            content: ogImg,
            vmid: 'twitter:image',
        },
    ])

    head.push(info)
}
