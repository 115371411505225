<script setup>
import {useTouch,} from "@/assets/js/src/util/composables/useTouch"
import {useTestimonials,} from "@/assets/js/src/util/composables/useTestimonials"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import colors from '@/assets/js/src/style/json/colors'
import {useLandingStore,} from "@/assets/js/src/modules/chat/_pinia/landing"
import {getActivePinia, storeToRefs,} from "pinia"

const landingStore = useLandingStore(getActivePinia())
const {testimonials: items,} = storeToRefs(landingStore)
const {
    notrans,
    indexes,
    testimonialStep,
    next,
    prev,
} = useTestimonials({
    items,
})
useTouch({
    elemSelector: '.chat-testimonials__wrapper__carousel',
    threshold: 72,
    goPrev: function () {
        prev()
    },
    goNext: function () {
        next()
    },
})
const {t,} = useTranslation()
</script>

<template>
    <div
        id="testimonials"
        class="chat-testimonials"
    >
        <div class="stroke bs xl float-left"></div>
        <h2>
            {{ t('async.nicodemus-ai.landing.testimonials.heading') }}
        </h2>
        <v-row
            class="fill-height"
        >
            <v-col
                cols="12"
                md="8"
                offset-md="2"
                class="chat-testimonials__wrapper"
            >
                <div
                    class="chat-testimonials__wrapper__carousel"
                >
                    <div
                        v-for="index in indexes"
                        :key="`testimonial-screen-${items[index].id}`"
                        :style="`transform: translateX(${-1 * testimonialStep * 100}%);`"
                        :class="['chat-testimonials__wrapper__carousel__item',{notrans,}]"
                    >
                        <div class="chat-testimonials__wrapper__testimonial">
                            <div class="chat-testimonials__wrapper__testimonial--text">
                                <p>{{ items[index].content }}</p>
                                <p>{{ items[index].name }}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="index in indexes.slice(0,2)"
                        :key="`testimonial-screen-clone-${items[index].id}`"
                        :style="`transform: translateX(${-1 * testimonialStep * 100}%);`"
                        :class="['chat-testimonials__wrapper__carousel__item',{notrans,}]"
                    >
                        <div class="chat-testimonials__wrapper__testimonial">
                            <div class="chat-testimonials__wrapper__testimonial--text">
                                <p>{{ items[index].content }}</p>
                                <p>{{ items[index].name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <v-btn
                    v-if="items.length > 0"
                    class="elevation-0 chat-testimonials__wrapper__button chat-testimonials__wrapper__button--left"
                    :aria-label="t('async.common.button.prev')"
                    icon
                    variant="plain"
                    @click="prev()"
                >
                    <v-icon
                        :color="$vuetify.theme.current.dark ? colors.white : colors.black"
                    >
                        bs:$vuetify.icons.mdiArrowLeft
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="items.length > 0"
                    class="elevation-0 chat-testimonials__wrapper__button chat-testimonials__wrapper__button--right"
                    :aria-label="t('async.common.button.next')"
                    icon
                    variant="plain"
                    @click="next()"
                >
                    <v-icon
                        :color="$vuetify.theme.current.dark ? colors.white : colors.black"
                    >
                        bs:$vuetify.icons.mdiArrowRight
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.bs-app {
    .chat {
        &-testimonials {

            h2 {
                width: 100%;
                margin: 0 0 52px;
                padding: 0;
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                font-weight: 200;
                font-size: 48px;
                text-align: center;
            }

            .testimonialslidein-leave-active,
            .testimonialslidein-enter-active {
                transition: 1s;
            }

            .testimonialslidein-enter-from {
                transform: translate(-100%, 0);
            }

            .testimonialslidein-leave-to {
                transform: translate(100%, 0);
            }

            .testimonialslideout-leave-active,
            .testimonialslideout-enter-active {
                transition: 1s;
            }

            .testimonialslideout-enter-from {
                transform: translate(-100%, 0);
            }

            .testimonialslideout-leave-to {
                transform: translate(100%, 0);
            }

            position: relative;
            z-index: 2;
            padding: 115px 0 96px;
            background: map-deep-get($bs-color, white);
            border-top: 4px solid map-deep-get($bs-color, greyLight);

            @include dark {
                background: map-deep-get($bs-color, dark, white);
                border-top: 4px solid map-deep-get($bs-color, dark, greyLight);
            }

            &__wrapper {
                position: relative;
                padding: 0 130px;
                overflow: hidden;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    position: relative;
                    padding: 0 50px;
                    overflow: hidden;
                }

                &__carousel {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;

                    &__item {
                        flex-shrink: 0;
                        width: 100%;
                        transition: transform .3s;
                    }
                }

                &__testimonial {
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        flex-wrap: wrap;
                    }

                    &--text {
                        width: 100%;
                        padding: 0 0 0 42px;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            width: 100%;
                            margin: 16px 0 0;
                            padding: 0;
                        }

                        p {
                            hyphens: auto;
                        }

                        p:first-of-type {
                            font-weight: 200;
                            font-size: 24px;
                            line-height: 28px;
                        }

                        p:last-of-type {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }

                &__button {
                    position: absolute;
                    top: 50%;
                    width: 36px;
                    height: 36px;
                    transform: translateY(-50%);

                    &--left {
                        left: 0;
                    }

                    &--right {
                        right: 0;
                    }
                }
            }
        }
    }
}
</style>
