import {computed,} from 'vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {ERROR, LOADED, usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import {useLandingStore,} from "@/assets/js/src/modules/chat/_pinia/landing"

export function useLandingApi () {
    let landingStore = useLandingStore(getActivePinia())
    let {testimonials,} = storeToRefs(landingStore)

    const {messagesLoaded, asyncStatus,allAsyncLoaded,} = usePrefetchData({
        actions: [
            {
                item: testimonials,
                action: landingStore.getChatLanding(),
            },
        ],
        loadAsyncMessages: [ 'nicodemus-ai', 'common', 'menu', 'home', ],
    })
    
    const asyncStatusLoaded = computed(()=>asyncStatus.value === LOADED)
    const asyncStatusError = computed(()=>asyncStatus.value === ERROR)

    return {
        messagesLoaded,
        asyncStatusLoaded,
        asyncStatusError,
        testimonials,
        allAsyncLoaded,
    }
}
