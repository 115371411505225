import {defineStore, getActivePinia,} from "pinia"
import fetch from '@/assets/js/src/util/fetch'
import {getDefaultHeaders,} from "@/assets/js/src/util/fetch/defaultHeaders"
import {checkResponse, handleException,} from "@/assets/js/src/util/apiTools"

export const API_CHAT_TESTIMONIALS = '/api/chat-testimonials'

export const useLandingStore = defineStore('chatLanding', {
    state: () => ({
        testimonials: [],
    }),
    actions: {
        async getChatLanding () {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_CHAT_TESTIMONIALS,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })
                
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.testimonials = apiResponse.data ?? []
            } catch (e) {
                handleException(e, true, false)
            }
        },
    },
})
