<script setup>
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {useCookieConsent,} from "@/assets/js/src/util/composables/useCookieConsent"
import {copyrightAutolink,} from "@/assets/js/src/util/imgTools"
import {onMounted,} from "vue"

const previewVideo = import.meta.glob('@/assets/images/nicodemus-ai/video-preview-de.jpg', {eager: true,})['/assets/images/nicodemus-ai/video-preview-de.jpg'].default

const {t,} = useTranslation()
const {acceptAll,} = useCookieConsent()
const copyright = copyrightAutolink({copyright: 'ERF - Der Sinnsender',})

onMounted(()=>{
    globalThis.Cookiebot && globalThis.Cookiebot.init()
})
</script>

<template>
    <div
        id="chat-video"
        class="chat-video"
    >
        <div
            class="stroke bs"
            style=" right: auto;left: 30%;"
        ></div>
        <h2>
            {{ t('async.nicodemus-ai.landing.video.heading') }}
        </h2>
        <v-row
            class="fill-height"
        >
            <v-col
                cols="12"
                md="6"
                offset-md="3"
            >
                <v-no-ssr>
                    <div
                        class="video-item__item"
                    >
                        <iframe
                            data-src="https://www.youtube-nocookie.com/embed/IuQPgH3TmuA?showinfo=0&rel=0"
                            :title="t('async.nicodemus-ai.landing.subtitle')"
                            data-cookieconsent="marketing"
                            frameborder="0"
                            scrolling="no"
                            allow="accelerometer; encrypted-media; gyroscope;"
                            allowfullscreen
                        ></iframe>
                        <figure class="cookieconsent-optout-marketing">
                            <img
                                class="no-ssr-placeholder__img"
                                :src="previewVideo"
                                :alt="t('async.nicodemus-ai.landing.subtitle')"
                            />
                            <div class="no-ssr-placeholder__img--bg"></div>
                            <div
                                class="no-ssr-placeholder__img--btn"
                            >
                                <span
                                    class="text-white"
                                    v-html="t('async.common.cookie.accept-marketing-text')"
                                ></span>
                                <v-btn
                                    @click.stop.prevent="acceptAll($event)"
                                >
                                    {{ t('async.common.cookie.accept-btn') }}
                                </v-btn>
                            </div>
                            <span
                                v-if="copyright"
                                class="no-ssr-placeholder__img--copyright"
                            >{{ copyright }}</span>
                        </figure>
                    </div>
                    <template
                        #placeholder
                    >
                        <figure class="no-ssr-placeholder__img--wrapper">
                            <img
                                class="no-ssr-placeholder__img"
                                :src="previewVideo"
                                :alt="t('async.nicodemus-ai.landing.subtitle')"
                            />
                            <span
                                v-if="copyright"
                                class="no-ssr-placeholder__img--copyright"
                            >{{ copyright }}</span>
                        </figure>
                    </template>
                </v-no-ssr>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.bs-app .chat {
    &-video {
        position: relative;
        z-index: 2;
        padding: 115px 0 96px;
        background: map-deep-get($bs-color, white);
        border-top: 4px solid map-deep-get($bs-color, greyLight);

        @include dark {
            background: map-deep-get($bs-color, dark, white);
            border-top: 4px solid map-deep-get($bs-color, dark, greyLight);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            padding: 48px 16px;
        }

        h2 {
            width: 100%;
            margin: 0 0 52px;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            text-align: center;
        }

        .video-item__item {
            position: relative;
            left: 0;
            width: 100%;
            height: 0;
            padding-bottom: 56.2493%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        .no-ssr-placeholder__img {
            position: absolute;
            inset: 0;
            max-width: 100%;
            border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

            &--wrapper {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                overflow: hidden;
                background-repeat: no-repeat;
                background-position: top;
                background-size: 100% auto;
            }

            &--copyright {
                position: absolute;
                bottom: 4px;
                left: 0;
                padding: .2em .6em !important;
                overflow: hidden;
                color: #fff !important;
                font-weight: 900;
                font-size: 11px;
                font-size: .6875rem;
                text-align: left;
                text-shadow: 1px 1px 2px rgb(0 0 0 / 80%), -1px 1px 2px rgb(0 0 0 / 80%), 1px -1px 2px rgb(0 0 0 / 80%), -1px -1px 2px rgb(0 0 0 / 80%);
                background-color: transparent;
                opacity: .8;
            }

            &--bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: map-deep-get($bs-color, greyLight);
                border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);
                opacity: .5;
                inset: 0;
            }

            &--btn {
                position: absolute;
                top: 50%;
                left: 50%;
                color: map-deep-get($bs-color, white);

                @include dark {
                    color: map-deep-get($bs-color, dark, white);
                }

                text-align: center;
                text-shadow: 1px 1px 2px rgb(0 0 0 / 80%), -1px 1px 2px rgb(0 0 0 / 80%), 1px -1px 2px rgb(0 0 0 / 80%), -1px -1px 2px rgb(0 0 0 / 80%);
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>
