<script setup>
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'
import {getActivePinia,} from "pinia"
import {useChatItemStore,} from "@/assets/js/src/modules/chat/_pinia/chatItem"

const {t,} = useTranslation()
const activePinia = getActivePinia()
const chatItemStore = useChatItemStore(activePinia)
</script>

<template>
    <v-btn
        color="primary"
        @click="chatItemStore.initChat()"
        @keyup="chatItemStore.initChat()"
    >
        {{ t('async.nicodemus-ai.landing.try_chat') }}
    </v-btn>
</template>
