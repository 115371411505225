<script setup>
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

const {t,} = useTranslation()
</script>

<template>
    <div
        id="chat-faq"
        class="chat-faq"
    >
        <h2>
            {{ t('async.nicodemus-ai.landing.faq.heading') }}
        </h2>
        <v-row>
            <v-col
                cols="12"
                md="6"
                offset-md="3"
            >
                <v-expansion-panels
                    multiple
                    class="pa-3"
                >
                    <v-expansion-panel
                        v-for="n in 10"
                        v-show="(`async.nicodemus-ai.landing.faq.question_${n}` !== t(`async.nicodemus-ai.landing.faq.question_${n}`, null, false) || !t(`async.nicodemus-ai.landing.faq.question_${n}`, null, false))"
                        :key="n"
                        elevation="2"
                    >
                        <v-expansion-panel-title>
                            {{ t(`async.nicodemus-ai.landing.faq.question_${n}`, null, false) }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p
                                class="content"
                                v-html="t(`async.nicodemus-ai.landing.faq.answer_${n}`, null, false)"
                            ></p>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.bs-app .chat {
    &-faq {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 92px 0 110px;
        background: map-deep-get($bs-color, greyLight);

        @include dark {
            background: map-deep-get($bs-color, dark, greyLight);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            padding: 48px 16px;
        }

        h2 {
            width: 100%;
            margin: 0 0 52px;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            text-align: center;
        }

        .v-expansion-panels {
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-size: 16px;
            box-shadow: none;

            .v-expansion-panel {
                &--active {
                    margin-bottom: 16px;
                    background-color: map-deep-get($bs-color, white);

                    @include dark {
                        background-color: map-deep-get($bs-color, dark, white);
                    }
                }

                p.content {
                    padding: 0 24px;

                    a {
                        color: map-deep-get($bs-color, black);

                        @include dark {
                            color: map-deep-get($bs-color, dark, black);
                        }
                    }
                }

                a.flat-btn {
                    margin-bottom: 12px;
                    color: map-deep-get($bs-color, greyDark);
                    font-weight: 700;
                }

                &-title {
                    color: map-deep-get($bs-color, overlay, greyDark);
                    font-weight: 400;
                    font-size: 16px;

                    @include dark {
                        color: map-deep-get($bs-color, dark, overlay, greyDark);
                    }

                    &__overlay {
                        pointer-events: none;
                    }

                    &:focus, &:hover {
                        background-color: map-deep-get($bs-color, white) !important;

                        @include dark {
                            background-color: map-deep-get($bs-color, dark, white) !important;
                        }

                        &::before {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
