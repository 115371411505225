import {onMounted, ref, watch,} from "vue"

export const useTestimonials = ({items,}) => {
    const notrans = ref(false)
    const indexes = ref([])
    const testimonialStep = ref(1)
    for (let i = 0; i < items.value.length; i++) {
        indexes.value.push(i)
    }

    onMounted(() => {
        indexes.value.sort(function () {
            return 0.5 - Math.random()
        })
    })

    watch(testimonialStep, (newValue) => {
        if (newValue === items.value.length + 1) {
            setTimeout(() => {
                notrans.value = true
                testimonialStep.value = 1
            }, 350) // Delay for transition
        } else if (newValue === 0) {
            setTimeout(() => {
                notrans.value = true
                testimonialStep.value = items.value.length
            }, 350) // Delay for transition
        } else if (newValue === 1 || newValue === items.value.length) {
            setTimeout(() => {
                notrans.value = false
            }, 50) // Delay for transition
        }
    })

    return {
        notrans,
        indexes,
        testimonialStep,
        next () {
            testimonialStep.value = testimonialStep.value + 1
        },
        prev () {
            testimonialStep.value = testimonialStep.value - 1
        },
    }
}
