<script setup>
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'
import BsVImg from "@/assets/js/src/components/BsVImg"
import ChatBubble from "@/assets/js/src/modules/chat/_components/landing/ChatBubble.vue"
import {getActivePinia, storeToRefs,} from "pinia"
import {useLangStore,} from "@/assets/js/src/modules/lang/_pinia/lang"
import NikoSvg from "@/assets/js/src/modules/chat/_components/NikoSvg.vue"
import {computed,} from "vue"

let activePinia = getActivePinia()

const {t,} = useTranslation()
const {locale,} = storeToRefs(useLangStore(activePinia))
const chatImgs = import.meta.glob('@/assets/images/nicodemus-ai/chat-screenshot*.png', {eager: true,})
const chatImg = computed(()=>{
    return chatImgs[
        '/assets/images/nicodemus-ai/chat-screenshot-' + locale.value + '.png'
    ]?.default ?? chatImgs['/assets/images/nicodemus-ai/chat-screenshot.png']?.default
})
</script>

<template>
    <div
        id="chat-features"
        class="chat-features"
    >
        <h2>
            {{ t('async.nicodemus-ai.landing.feature.heading') }}
        </h2>
        <v-row>
            <v-col
                cols="12"
                md="4"
                offset-md="2"
            >
                <div class="chat-features__intro">
                    <p>{{ t(`async.nicodemus-ai.landing.feature.introduction`) }}</p>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="4"
                class="position-relative"
            >
                <div
                    class="stroke bs-h xxl float-right hidden-md-and-up"
                    style="top: 70%;right: -40%;"
                ></div>
                <div
                    class="stroke bs-h xxl float-right hidden-sm-and-down "
                    style="top: 80%;right: -40%;"
                ></div>
                <div class="chat-features__pic">
                    <bs-v-img
                        :src="chatImg"
                        :class="['secondary--text','hidden-print-only']"
                    >
                        <template #placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    :class="['secondary--text']"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </bs-v-img>
                </div>
            </v-col>
        </v-row>
    </div>
    <div
        id="chat-examples"
        class="chat-examples"
    >
        <div
            class="stroke bs xl float-left"
            style="z-index: 2;"
        ></div>
        <v-row>
            <v-col
                cols="12"
                md="8"
                offset-md="2"
            >
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        class="pt-16"
                    >
                        <h2>{{ t('async.nicodemus-ai.landing.examples.heading') }}</h2>
                        <p>{{ t('async.nicodemus-ai.landing.examples.text') }}</p>
                    </v-col>
                </v-row>
                <div
                    class="chat-examples__wrapper"
                >
                    <niko-svg
                        size="129px"
                        style="position: absolute;top: 10%; z-index: 1;"
                    ></niko-svg>
                    <chat-bubble
                        class="bs hidden-md-and-up"
                        style="top: 10%;right: 12px;left: auto;width: 11.5rem;"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_1')"
                    ></chat-bubble>
                    <chat-bubble
                        class="light t-right hidden-md-and-up"
                        style=" top: 38%; left: 0;width: 15rem"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_2')"
                    ></chat-bubble>
                    <chat-bubble
                        class="bs-h hidden-md-and-up"
                        style=" top: 67%; right: 15%; left: auto; width: 16.5rem;"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_3')"
                    ></chat-bubble>
                    <chat-bubble
                        class="bs hidden-sm-and-down"
                        style="top: 10%;right: auto;left: 18%;width: 13.5rem;"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_1')"
                    ></chat-bubble>
                    <chat-bubble
                        class="light t-right hidden-sm-and-down"
                        style=" top: 14rem; left: 0;width: 15rem"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_2')"
                    ></chat-bubble>
                    <chat-bubble
                        class="bs-h hidden-sm-and-down"
                        style=" top: 30%; left: 34%;width: 16.5rem;"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_3')"
                    ></chat-bubble>
                    <chat-bubble
                        class="light t-right hidden-sm-and-down"
                        style=" right: 0; left: auto;width: 15.5rem"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_4')"
                    ></chat-bubble>
                    <chat-bubble
                        class="bs t-right hidden-sm-and-down"
                        style=" top: 230px; right: 5%; left: auto;width: 15rem;"
                        :text="t('async.nicodemus-ai.landing.examples.bubble_5')"
                    ></chat-bubble>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.bs-app .chat {
    &-features {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 92px 0 110px;
        background: map-deep-get($bs-color, greyLight);

        @include dark {
            background: map-deep-get($bs-color, dark, greyLight);
        }

        h2 {
            width: 100%;
            margin: 0 0 52px;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            text-align: center;
        }

        &__pic {
            position: absolute;
            top: 10%;
            right: 0;
            width: 100%;
            max-width: 446px;

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                top: 15%;
                width: 90%;
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                position: relative;
            }
            .v-img {
                overflow: inherit;
            }
            img {
                filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
            }
        }

        & > div:not(:first-of-type) {
            margin-top: 4px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                margin-top: 0;
            }
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            padding: 48px 16px 96px;

            & > div > div {
                margin-top: 4px;
            }

            & > div:first-of-type > div:first-of-type {
                margin-top: 0;
            }
        }

        & > div:last-of-type > div:last-of-type {
            h3 {
                svg {
                    height: 36px !important;
                }
            }
        }

        &__intro p {
            font-weight: 300;
            font-size: 24px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                font-size: 20px;
            }
        }
    }

    &-examples {
        position: relative;
        background: map-deep-get($bs-color, white);
        border-top: 1px solid transparent;

        @include dark {
            background: map-deep-get($bs-color, dark, white);
        }

        &__wrapper {
            position: relative;
            z-index: 2;
            width: 100%;
            min-height: 400px;
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            padding: 0 16px;

            &__wrapper {
                min-height: 500px;
            }
        }
    }
}
</style>
